import { Modal } from '@mui/material'
import ModalCanvas from './ModalCanvas'
import { useIntl } from 'react-intl'
import intl from 'localization/components'
import { useFraudIndicatorsPolicyContext } from 'pages/fraud-indicators/FraudIndicatorsProvider'
import { useSnackbar } from 'notistack'
import { FormattedMessage } from 'react-intl'
import { useFraudIndicatorsPolicyUpdate } from 'services/queries'

const ActivateFraudIndicatorsModal = () => {
  const {
    isEnabled,
    indicators,
    selectedCountry,
    isActivationModalOpen: isOpen,
    setIsActivationModalOpen: setIsOpen,
  } = useFraudIndicatorsPolicyContext()
  const reactIntl = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const onSuccess = () => {
    enqueueSnackbar(
      <FormattedMessage id={intl.fraudIndicators('save-changes-success')} />,
      {
        variant: 'success',
      }
    )
    setIsOpen(false)
  }
  const onError = () => {
    enqueueSnackbar(intl.fraudIndicators('save-changes-error'), {
      variant: 'error',
    })
    setIsOpen(false)
  }
  const { mutate, isLoading: isSaving } = useFraudIndicatorsPolicyUpdate({
    onSuccess,
    onError,
  })
  const handleSave = (enable: boolean) => {
    const payload = {
      enabled: enable,
      policy: indicators,
      country: selectedCountry,
    }
    mutate(payload)
  }
  return (
    <Modal
      open={isOpen}
      onClose={() => {
        if (!isSaving) {
          setIsOpen(false)
        }
      }}
      closeAfterTransition
    >
      {isEnabled ? (
        <ModalCanvas
          title={reactIntl.formatMessage({
            id: intl.fraudIndicators('deactivate-fraud-indicators-title'),
          })}
          text={reactIntl.formatMessage({
            id: intl.fraudIndicators('deactivate-fraud-indicators-description'),
          })}
          primaryBtnTitle={reactIntl.formatMessage({
            id: intl.generic('continue'),
          })}
          secondaryBtnTitle={reactIntl.formatMessage({
            id: intl.generic('cancel'),
          })}
          primaryBtnFunc={() => handleSave(false)}
          secondaryBtnFunc={() => setIsOpen(false)}
          primaryBtnDisabled={isSaving}
          secondaryBtnDisabled={isSaving}
          closeFn={() => {
            if (!isSaving) {
              setIsOpen(false)
            }
          }}
        />
      ) : (
        <ModalCanvas
          title={reactIntl.formatMessage({
            id: intl.fraudIndicators('activate-fraud-indicators-title'),
          })}
          text={reactIntl.formatMessage({
            id: intl.fraudIndicators('activate-fraud-indicators-description'),
          })}
          primaryBtnTitle={reactIntl.formatMessage({
            id: intl.generic('continue'),
          })}
          secondaryBtnTitle={reactIntl.formatMessage({
            id: intl.generic('cancel'),
          })}
          primaryBtnFunc={() => handleSave(true)}
          secondaryBtnFunc={() => setIsOpen(false)}
          primaryBtnDisabled={isSaving}
          secondaryBtnDisabled={isSaving}
          closeFn={() => {
            if (!isSaving) {
              setIsOpen(false)
            }
          }}
        />
      )}
    </Modal>
  )
}

export default ActivateFraudIndicatorsModal
