import { LocalId } from 'globalTypes'
import risikaAPI from 'services/api/helpers/risikaAPI'

/******************
 * Fetch from API *
 ******************/

export interface Highlight {
  classification: 'positive' | 'negative' | 'neutral'
  data: any
  description: string
  message: string
  title: string
  weight: number
}

type Indicator = {
  value: number
  last_updated: string
}

type FraudIndicatorResponse = {
  indicators: {
    [key: string]: Indicator
  }
  count: number
}

type ResponseData = {
  data: FraudIndicatorResponse
}

async function fraudIndicators({
  id,
  country,
}: LocalId): Promise<FraudIndicatorResponse> {
  const { data }: ResponseData = await risikaAPI.get(
    `${country}/fraud_indicators/${id}`,
    {
      microservice: 'data',
    }
  )

  return data
}

export default fraudIndicators
