export const generalLabelKeys = [
  'company-id',
  'company-name',
  'company-type',
  'date-of-incorporation',
  'email',
  'status',
  'risika-score',
  'fraud-indicators',
  'credit-max',
  'phone',
  'street',
  'street-number',
  'coname',
  'zip-code',
  'city',
  'municipality',
  'postal-district',
  'country',
  'number-of-employees',
  'industry-code',
  'industry-description',
  'internal-id',
]

export const financialLabelKeys = [
  'currency',
  'equity',
  'profit-loss',
  'solidity',
  'liquidity',
  'capital',
  'EBITDA',
  'ebit',
  'ordinaryProfit',
  'shortTermDebt',
  'longTermDebt',
  'shortTermTradePayables',
  'shortTermReceivablesFromSalesAndServices',
  'start-date',
  'end-date',
]
