import { useState, useMemo } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { Hide, HumanDate } from 'components'
import moment from 'moment'
import { navigation } from 'services/navigation'
import { Link } from 'react-router-dom'
import { isNil } from 'ramda'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { isFraudIndicatorType } from 'components-new/data-display/Table/elements/Changes/Changes.controller'

import { FormattedMessage, FormattedNumber } from 'react-intl'
import intl from 'localization/components'
import { classes } from '../../../styles/change'

const LinkWrap = ({ data, children }) =>
  data ? (
    <Link to={navigation.relationOverview(data)} style={{ textDecoration: 'none' }}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  )

const Change = ({ change }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { plan } = useSelector((state) => state.risika.permissions.company)
  const reactIntl = useIntl()

  const canShowChange = (change) => {
    if (plan === 'FREEMIUM') return false

    return change?.type?.toLowerCase() !== 'score'
  }

  const handleChangeValue = (value) => {
    if (typeof value === 'object') {
      const keys = Object.keys(value)
      if (keys.includes('name')) {
        return value.name
      }
      if (keys.includes('function')) {
        return value.function
      }
      if (keys.includes('score') || keys.includes('probability_of_distress')) {
        return value.score ?? value.risk_assessment
      }
      if (keys.includes('amount') || keys.includes('creditor')) {
        if (value?.amount === null && value?.creditor === null) return 'Unknown'
        return `${value?.creditor ?? 'Unknown'}: ${value?.amount
            ? new Intl.NumberFormat('no-NO', {
              style: 'decimal',
            }).format(value?.amount) + ' NOK'
            : 'Unknown'
          }`
      }
      return 'Something is wrong!'
    }
    return value
  }

  const getTotalProps = (change) => {
    if (isFraudIndicatorType(change)) {
      return null
    }

    const value = change?.new?.value

    if (typeof value !== 'object') return null
    const total = {}
    if (value?.total_unsettled) {
      total.total_unsettled = value?.total_unsettled
    }
    if (value?.unique_creditors) {
      total.unique_creditors = value.unique_creditors
    }
    if (value?.total_unsettled) {
      total.total_unsettled_amount = value.total_unsettled_amount
    }
    if (Object.keys(total).length === 0) return null
    return total
  }

  const total = getTotalProps(change)

  const generateRiskInidicator = (key) => {
    return (
      <Typography
        key={key}
        data-cy={`fraud-indicator-${key}`}
        variant="body2"
        sx={classes.changeContent}
      >
        <FormattedMessage id={intl.fraudIndicators(key)} />
      </Typography>
    )
  }

  const riskDataPoints = useMemo(() => {
    const generatePlusOne = () => {
      return (
        <Typography
          key={`plus-1-${change.type}`}
          data-cy={`fraud-indicator-plus-one`}
          variant="body2"
          sx={classes.changeContent}
        >
          +1
        </Typography>
      )
    }
    if (isFraudIndicatorType(change)) {
      const newDataPoints = Object.keys(change.dataPoints ?? {})
        .filter((key) => change.dataPoints?.[key] === true)
        .map((key) => key)

      if (isExpanded) {
        return newDataPoints.map(generateRiskInidicator)
      }

      if (newDataPoints.length > 2) {
        return [
          ...newDataPoints.slice(0, 2).map(generateRiskInidicator),
          generatePlusOne(),
        ]
      }
      return newDataPoints.map(generateRiskInidicator)
    }
    return []
  }, [change, isExpanded])

  if (isFraudIndicatorType(change)) {
    return (
      riskDataPoints.length > 0 && (
        <Box sx={classes.root}>
          <Typography variant="subtitle1">
            {reactIntl.formatMessage({
              id: intl.fraudIndicators('risk-indicators-sentence'),
            })}
          </Typography>
          <Typography variant="body2" className="text-grey-dark">
            <HumanDate date={moment(change.date)} />
          </Typography>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            sx={{ cursor: 'pointer' }}
            mt={2}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {riskDataPoints}
          </Stack>
        </Box>
      )
    )
  }

  return (
    <Box sx={classes.root}>
      {!isNil(change.to) ? (
        <>
          <Box sx={classes.paddingAfterDays}>
            <Typography variant="subtitle1">{change.sentence}</Typography>
            <Typography variant="body2">
              <HumanDate date={moment(change.date)} />
            </Typography>
          </Box>
          {canShowChange(change) ? (
            <Box>
              <Box sx={classes.change}>
                {!isNil(change.from) && (
                  <>
                    <LinkWrap data={change.to.personal_id}>
                      <Typography
                        variant="body2"
                        sx={{
                          ...classes.changeContent,
                          ...change?.from?.personal_id,
                        }}
                      >
                        {handleChangeValue(change.from)}
                      </Typography>
                    </LinkWrap>
                    <Typography variant="body2" sx={classes.changeArrow}>
                      →
                    </Typography>
                  </>
                )}
                <LinkWrap data={change.to.personal_id}>
                  <Typography
                    variant="body2"
                    sx={{
                      ...classes.changeContent,
                      ...change.to.personal_id,
                    }}
                  >
                    {handleChangeValue(change.to)}
                  </Typography>
                </LinkWrap>
              </Box>
              <Hide when={change?.type !== 'newton'}>
                <Box>
                  <Typography variant="subtitle1" sx={classes.probability}>
                    <FormattedMessage id={intl.financial('probability-of-closure')} />
                  </Typography>
                  <Box sx={classes.change}>
                    <Typography variant="body2" sx={classes.changeContent}>
                      <FormattedNumber
                        value={change?.old?.value?.probability_of_distress}
                        maximumFractionDigits={2}
                        style="percent"
                      />
                    </Typography>
                    <Typography variant="body2" sx={classes.changeArrow}>
                      →
                    </Typography>
                    <Typography variant="body2" sx={classes.changeContent}>
                      <FormattedNumber
                        value={change?.new?.value?.probability_of_distress}
                        maximumFractionDigits={2}
                        style="percent"
                      />
                    </Typography>
                  </Box>
                </Box>
              </Hide>
            </Box>
          ) : null}
          {total && (
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              spacing={2}
              mt={2}
            >
              {total?.unique_creditors && (
                <Typography variant="body2" sx={classes.changeContent}>
                  <FormattedMessage id={intl.paymentRemarks('unique-creditors')} />
                  :&nbsp;
                  {total.unique_creditors}
                </Typography>
              )}
              {total?.total_unsettled && (
                <Typography variant="body2" sx={classes.changeContent}>
                  <FormattedMessage id={intl.paymentRemarks('total-unsettled-number')} />
                  :&nbsp;
                  {total.total_unsettled}
                </Typography>
              )}
              {total?.total_unsettled_amount && (
                <Typography variant="body2" sx={classes.changeContent}>
                  <FormattedMessage id={intl.paymentRemarks('total-unsettled-amount')} />
                  :&nbsp;
                  {new Intl.NumberFormat('no-NO', {
                    style: 'decimal',
                  }).format(total?.total_unsettled_amount) + ' NOK'}
                </Typography>
              )}
            </Stack>
          )}
        </>
      ) : (
        <Box>
          <Typography variant="subtitle1">{change.sentence}</Typography>
          <Typography variant="body2" sx="text-grey-dark">
            <HumanDate date={moment(change.date)} />
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default Change
