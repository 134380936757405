import risikaAPI from 'services/api/helpers/risikaAPI'
import { SupportedCountry } from 'types/general'

export type DataPoints =
  | 'financial_statement_abnormalities_year_over'
  | 'frequency_of_address_changes'
  | 'companies_from_same_address'
  | 'profit_vs_employees_abnormalities'
  | 'reverse_auditor_bankruptcy_changes'
  | 'duplicate_statements'

export type FraudIndicatorPolicyResponse = {
  country: string
  enabled: boolean
  policy: Record<DataPoints, boolean>
}

type ResponseData = {
  data: FraudIndicatorPolicyResponse
}

async function getFraudIndicatorsPolicy({
  country,
}: {
  country: SupportedCountry
}): Promise<FraudIndicatorPolicyResponse> {
  const resp: ResponseData = await risikaAPI.get(`/fraud_policy/get?country=${country}`, {
    microservice: 'data',
  })
  return resp.data
}

export default getFraudIndicatorsPolicy
