import React from 'react'
import { FieldWrapper, FieldTitle } from '../Field'
import DualLabelSwitch from '../DualLabelSwitch'
type FraudIndicatorsActivatorProps = {
  title: string
  description: string
  selected: boolean
  setSelected: (value: boolean) => void
  dataCy: string
}

const FraudIndicatorsActivator = ({
  title,
  description,
  selected,
  setSelected,
  dataCy,
}: FraudIndicatorsActivatorProps) => {
  return (
    <FieldWrapper>
      <FieldTitle title={title} description={description} />
      <DualLabelSwitch
        checked={selected}
        onChange={(_, checked) => setSelected(checked)}
        dataCy={dataCy}
      />
    </FieldWrapper>
  )
}

export default FraudIndicatorsActivator
