import React from 'react'
import { Modal } from '@mui/material'
import ModalCanvas from './ModalCanvas'
import { useFraudIndicatorsPolicyContext } from '../../FraudIndicatorsProvider'
import { useIntl } from 'react-intl'
import intl from 'localization/components'
import { FormattedMessage } from 'react-intl'
import { useSnackbar } from 'notistack'
import { useFraudIndicatorsPolicyUpdate } from 'services/queries'

const FraudIndicatorsSaveModal = () => {
  const {
    isSaveModalOpen: isOpen,
    setIsSaveModalOpen: setIsOpen,
    indicators,
    selectedCountry,
    setSelectedCountry,
    isEnabled,
    discardedTab,
    setDiscardedTab,
  } = useFraudIndicatorsPolicyContext()
  const reactIntl = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const onSuccess = () => {
    enqueueSnackbar(
      <FormattedMessage id={intl.fraudIndicators('save-changes-success')} />,
      {
        variant: 'success',
      }
    )
    setIsOpen(false)
  }
  const onError = () => {
    enqueueSnackbar(intl.fraudIndicators('save-changes-error'), {
      variant: 'error',
    })
    setIsOpen(false)
  }
  const { mutate, isLoading: isSaving } = useFraudIndicatorsPolicyUpdate({
    onSuccess,
    onError,
  })
  const handleSave = () => {
    const payload = {
      enabled: isEnabled,
      policy: indicators,
      country: selectedCountry,
    }
    mutate(payload)
  }
  return (
    <Modal
      open={isOpen}
      onClose={() => {
        if (!isSaving) {
          setIsOpen(false)
        }
      }}
      closeAfterTransition
    >
      <ModalCanvas
        title={reactIntl.formatMessage({
          id: intl.fraudIndicators('save_modal_title'),
        })}
        text={reactIntl.formatMessage({
          id: intl.fraudIndicators('save_modal_text'),
        })}
        primaryBtnTitle={reactIntl.formatMessage({
          id: intl.generic('save_changes'),
        })}
        secondaryBtnTitle={reactIntl.formatMessage({
          id: intl.generic('discard'),
        })}
        primaryBtnFunc={handleSave}
        secondaryBtnFunc={() => {
          setIsOpen(false)
          setDiscardedTab(null)
          setSelectedCountry(discardedTab!)
        }}
        primaryBtnDisabled={isSaving}
        secondaryBtnDisabled={isSaving}
        closeFn={() => {
          if (!isSaving) {
            setIsOpen(false)
          }
        }}
      />
    </Modal>
  )
}

export default FraudIndicatorsSaveModal
