import { useQuery } from 'react-query'
import { getFraudIndicatorsPolicy } from 'services/api'
import { isCallAllowed } from 'configs'
import { SupportedCountry } from 'types/general'

export function useFraudIndicatorsPolicyGet(
  country: SupportedCountry,
  isEnabled: boolean
) {
  return useQuery(
    ['getFraudIndicatorsPolicy', country],
    () => getFraudIndicatorsPolicy({ country }),
    {
      enabled: !!isCallAllowed('fraudIndicators', country) && isEnabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
}
