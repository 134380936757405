import risikaAPI from 'services/api/helpers/risikaAPI'
import { SupportedCountry } from 'types/general'
import { Indicators } from 'pages/fraud-indicators/types'

export type RequestData = {
  country: SupportedCountry
  enabled: boolean
  policy: Indicators
}

export type ResponseData = {
  data?: { status: string }
}

async function updateFraudIndicatorsPolicy(data: RequestData): Promise<ResponseData> {
  const resp: ResponseData = await risikaAPI.post(
    `/fraud_policy/set`,
    { ...data },
    {
      microservice: 'data',
    }
  )
  return resp
}

export default updateFraudIndicatorsPolicy
