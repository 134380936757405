import { Skeleton, Stack } from '@mui/material'

const SectionSkeleton = () => {
  return (
    <Stack direction="column" spacing={4}>
      <Stack direction="column" spacing={4}>
        <Skeleton width="40%" />
        <Skeleton variant="rounded" width="100%" height="85px" />
      </Stack>
      <Stack direction="column" spacing={4}>
        <Skeleton width="40%" />
        <Skeleton variant="rounded" width="100%" height="85px" />
      </Stack>
    </Stack>
  )
}

export default SectionSkeleton
