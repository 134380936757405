import { useMutation, useQueryClient } from 'react-query'
import { updateFraudIndicatorsPolicy } from 'services/api'
import {
  ResponseData,
  RequestData,
} from 'services/api/endpoints/risikaAPI/updateFraudIndicatorsPolicy'
import { WebApiError } from 'types/general'

type FraudIndicatorsPolicyUpdateProps = {
  onSuccess?: () => void
  onError?: () => void
}

export function useFraudIndicatorsPolicyUpdate({
  onSuccess,
  onError,
}: FraudIndicatorsPolicyUpdateProps) {
  const queryClient = useQueryClient()

  return useMutation<ResponseData, WebApiError, RequestData>(
    updateFraudIndicatorsPolicy,
    {
      onSuccess: () => {
        onSuccess?.()
        queryClient.invalidateQueries('getFraudIndicatorsPolicy')
      },
      onError: (err) => {
        onError?.()
        console.error(err)
      },
    }
  )
}
