import risikaAPI from 'services/api/helpers/risikaAPI'
import { LocalId } from 'globalTypes'
import { Address } from 'types/general'

/******************
 * Fetch from API *
 ******************/
export interface CompanyBasicsResponse {
  active_not_distress?: boolean
  address: Address
  advertisement_protection: boolean
  bank: null | {
    local_organization_id: LocalId
    company_name: string
  }
  company_secondary_names: {
    name: string
    valid_from: string
    valid_to?: string
  }[]
  company_name: string
  company_name_changes_count: string
  company_type: {
    short: string
    long: string
  }
  date_of_incorporation: string
  email: {
    email?: string
    hidden: boolean
  }
  financial_reports: boolean
  holding_company: boolean
  last_report_date?: string
  local_organization_id: LocalId
  main_industry_code: {
    code: string
    description: string
    isic: {
      code: string
      description: string
      section: string
    }
    nace: {
      code: string
      description: string
      section: string
    }
    section: string
  }
  number_of_employees: {
    interval: string
    specific: number
  }
  phone: {
    phone_number?: string
    hidden: boolean
  }
  powers_to_bind: string
  registered_capital: {
    currency: string
    value: number
  }
  score?: number | null
  secondary_industry_codes: {
    group_name: string
    industry_code: string
    industry_description: string
    isic: {
      code: string
      description: string
      section: string
    }
    nace: {
      code: string
      description: string
      section: string
    }
    priority: number
    section: string
  }
  status: string
  status_code: string
  vat: boolean
  webpage?: string
  risk_assessment?: string
  risk_assessment_code?: string
  purpose?: string
  audit_selected?: boolean
  financial_year: {
    start?: string
    end?: string
  }
  listed?: boolean
  credit_policy: {
    allowed: boolean
    conflicts: {
      code: string
      text: string
    }[]
  }
  status_valid_from?: string
  internal_id?: string
  address_changes_count: string
  average_number_of_employees: string
  ean: any
  auditor_selected?: boolean
}
interface ResponseData {
  data: CompanyBasicsResponse
}

function companyBasics({ id, country }: LocalId): Promise<CompanyBasicsResponse> {
  return risikaAPI
    .get(`/company/basics/${id}`, {
      microservice: 'data',
      country,
    })
    .then(({ data }: ResponseData) => {
      return {
        ...data,
        auditor_selected: data.audit_selected,
      }
    })
}

export default companyBasics
