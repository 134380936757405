import { useState, useMemo } from 'react'
import {
  ColumnsDefinition,
  SelectedType,
  TableDataAPIType,
} from 'components-new/data-display/Table/Table.types'
import { TableWithToolbar, ErrorPageSimple } from 'components-new'
import {
  monitoringColumnsDefinition,
  blankTableAPIData,
} from './ECPMonitoringTable.model'

import MonitoringTableToolbar from './widgets/MonitoringTableToolbar'
import lodash from 'lodash'
import { countActiveFilters } from '../../../observational-lists/ObservationalListsPage.controller'
import { riskIntelligenceApiKeys as apiKeys } from '../../../../configs/constants/api'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import { useCreditAutomatorTableContext } from './context'
import { formatData } from './ECPMonitoringTable.controller'
import { getFeatureFlag } from 'feature-flags/getFeatureFlag'
import { FEATURE_FLAGS } from 'feature-flags/FeatureFlags'
import { useAccountStatus, useUserSettings } from 'services/queries'
import { env } from 'services/env'
import { AvailableCurrencies } from 'globalTypes'

export type CreditAutomatorMonitoringTableProps = {
  data: TableDataAPIType | undefined
  entireData: TableDataAPIType | undefined
  listId: number
  listName: string
  selectedCurrency: AvailableCurrencies
  tableFilters: any
  setTableFilters: any
  isLoading: boolean
  isFiltersLoading: boolean
  isError: boolean
  refetchData: () => void
}
export const ECPMonitoringTable = ({
  data,
  entireData,
  listId,
  listName,
  selectedCurrency,
  tableFilters,
  setTableFilters,
  isLoading,
  isFiltersLoading,
  isError,
  refetchData,
}: CreditAutomatorMonitoringTableProps) => {
  const { actions } = useCreditAutomatorTableContext()
  const [selected, setSelected] = useState<SelectedType[]>([])
  const accountStatus = useAccountStatus()
  const { data: settingsData } = useUserSettings()
  const reactIntl = useIntl()
  const monitorTableFields = settingsData?.monitorTableFields ?? []
  const { rights } = accountStatus.data ?? {}
  const global = rights?.global
  const hasAccessToFraudModule =
    (global?.fraud_indicators && monitorTableFields.includes('fraudIndicators')) ||
    env.FRAUD_MVP_DEMO_ENV === 'true' ||
    getFeatureFlag(FEATURE_FLAGS.FRAUD_INDICATORS)

  const columnsDefinition = hasAccessToFraudModule
    ? monitoringColumnsDefinition
    : monitoringColumnsDefinition.filter((item) => item.key !== 'fraud_indicators')

  const handleFilters = (category: string, key: string) => {
    const nextFilterState: any = lodash.cloneDeep(tableFilters)
    // checkedValue is opposite of field current value
    let checkedValue = !nextFilterState[category][key].checked
    // But if there is only one checked value, user is not allowed to uncheck it
    if (
      (category === apiKeys.creditAutomatorResult || category === apiKeys.creditScore) &&
      countActiveFilters(nextFilterState[category]) <= 1
    ) {
      checkedValue = true
    }
    nextFilterState[category][key].checked = checkedValue
    setTableFilters(nextFilterState)
    actions.updatePage(1)
  }

  const handleSearch = (searchTerm: string) => {
    if (!Object.keys(tableFilters).length) return
    const nextFilterState: any = lodash.cloneDeep(tableFilters)
    nextFilterState[apiKeys.freeSearch] = searchTerm
    setTableFilters(nextFilterState)
    actions.updatePage(1)
  }

  // TODO: remove after fraud_indicators integration
  const generateDataPoints = () => {
    const dataPoints: any = []
    const index = Math.floor(Math.random() * 3)
    const keys = [
      { type: 'distance_to_ceo', sentence: 'Distance to CEO' },
      { type: 'duplicate_statements', sentence: 'Duplicate statements' },
      { type: 'frequency_of_address_changes', sentence: 'Frequency of address changes' },
    ]

    keys.forEach((key, ind) => {
      if (index === ind) {
        dataPoints.push({
          type: key.type,
          new: {
            valid_from: '2023-01-01',
            valid_to: null,
            value: Math.floor(Math.random() * 10),
          },
          sentence: key.sentence,
          weight: Math.floor(Math.random() * 1000),
          registration_date: new Date().toISOString().split('T')[0],
        })
      } else {
        if (Math.random() > 0.5) {
          dataPoints.push({
            type: key.type,
            new: {
              valid_from: '2023-01-01',
              valid_to: null,
              value: Math.floor(Math.random() * 10),
            },
            sentence: key.sentence,
            weight: Math.floor(Math.random() * 1000),
            registration_date: new Date().toISOString().split('T')[0],
          })
        }
      }
    })
    return dataPoints
  }

  // TODO: Remove this dummy data when fraud indicators are available
  const adjustedData = useMemo(() => {
    if (!data) return undefined
    if (!hasAccessToFraudModule) return data
    const datas = data?.data ?? []
    const newData = datas.map((item) => {
      const rand = Math.random()
      const adjustedChanges =
        rand > 0.5 ? [...(item.changes ?? []), ...generateDataPoints()] : item.changes
      return {
        ...item,
        fraud_indicators: rand,
        changes: adjustedChanges,
      }
    })
    return {
      ...data,
      data: newData,
    }
  }, [data, hasAccessToFraudModule, reactIntl])

  if (isError) {
    return (
      <ErrorPageSimple
        content={<FormattedMessage id={intl.riskMonitoringNew('error-loading-page')} />}
      />
    )
  }

  return (
    // Table with toolbar is unnecessary. You can just render it like the loading state above
    <TableWithToolbar
      tableData={adjustedData ?? blankTableAPIData}
      entireData={entireData ?? blankTableAPIData}
      formatData={formatData}
      columnsDefinition={columnsDefinition as ColumnsDefinition}
      getSelected={setSelected}
      expandable={true}
      isLoading={isLoading}
      enableReorder
      refetch={refetchData}
      listId={listId}
    >
      <MonitoringTableToolbar
        isLoading={isFiltersLoading}
        listName={listName}
        listId={listId}
        selectedCurrency={selectedCurrency}
        selectedRows={selected}
        refetch={() => refetchData()}
        handleFilters={handleFilters}
        handleSearch={handleSearch}
        tableFilters={tableFilters}
      />
    </TableWithToolbar>
  )
}
