import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { SupportedCountry } from 'types/general'
import { FraudIndicatorsType, Indicators } from './types'
import lodash from 'lodash'
import { useAccountStatus, useFraudIndicatorsPolicyGet } from 'services/queries'

const initialData: Indicators = {
  financial_statement_abnormalities_year_over: false,
  frequency_of_address_changes: false,
  companies_from_same_address: false,
  profit_vs_employees_abnormalities: false,
  reverse_auditor_bankruptcy_changes: false,
  duplicate_statements: false,
}

const FraudIndicatorsContext = createContext<FraudIndicatorsType>({
  selectedCountry: 'dk',
  setSelectedCountry: () => {},
  indicators: initialData,
  setIndicators: () => {},
  isEnabled: false,
  setIsEnabled: () => {},
  isSaveModalOpen: false,
  setIsSaveModalOpen: () => {},
  isActivationModalOpen: false,
  setIsActivationModalOpen: () => {},
  hasFraudIndicatorsChanged: false,
  fraudIndicatorsPolicyData: undefined,
  isFraudIndicatorsPolicyDataFetching: false,
  discardedTab: null,
  setDiscardedTab: (() => {}) as React.Dispatch<
    React.SetStateAction<SupportedCountry | null>
  >,
})

export const useFraudIndicatorsPolicyContext = () => useContext(FraudIndicatorsContext)

type Props = {
  children: React.ReactNode
}

const FraudIndicatorsProvider = ({ children }: Props) => {
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false)
  const [isActivationModalOpen, setIsActivationModalOpen] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState<SupportedCountry>('dk')
  const [discardedTab, setDiscardedTab] = useState<SupportedCountry | null>(null)
  const [indicators, setIndicators] = useState(initialData)
  const [isEnabled, setIsEnabled] = useState(false)

  const { data: accountStatusData } = useAccountStatus()
  const {
    data: fraudIndicatorsPolicyData,
    isFetching: isFraudIndicatorsPolicyDataFetching,
  } = useFraudIndicatorsPolicyGet(
    selectedCountry,
    !!accountStatusData?.rights?.global?.fraud_indicators
  )

  useEffect(() => {
    if (!fraudIndicatorsPolicyData) {
      setIndicators(initialData)
      setIsEnabled(false)
      return
    }
    const { policy } = fraudIndicatorsPolicyData
    setIndicators(policy)
    setIsEnabled(!!fraudIndicatorsPolicyData.enabled)
  }, [fraudIndicatorsPolicyData, isFraudIndicatorsPolicyDataFetching])

  const hasFraudIndicatorsChanged: boolean = useMemo(() => {
    const { policy } = fraudIndicatorsPolicyData ?? { policy: undefined }
    const initialIndicators = {
      ...(policy ?? initialData),
    }
    const currentIndicators = { ...indicators }
    const res = !lodash.isEqual(initialIndicators, currentIndicators)
    return res
  }, [fraudIndicatorsPolicyData, indicators])

  return (
    <FraudIndicatorsContext.Provider
      value={{
        selectedCountry,
        setSelectedCountry,
        indicators,
        setIndicators,
        isEnabled,
        setIsEnabled,
        isSaveModalOpen,
        setIsSaveModalOpen,
        isActivationModalOpen,
        setIsActivationModalOpen,
        hasFraudIndicatorsChanged,
        fraudIndicatorsPolicyData,
        isFraudIndicatorsPolicyDataFetching,
        discardedTab,
        setDiscardedTab,
      }}
    >
      {children}
    </FraudIndicatorsContext.Provider>
  )
}

export default FraudIndicatorsProvider
