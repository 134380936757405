import { queryClient } from 'index'

export const queryFind = (key) => {
  return queryClient.getQueryData(key)
}
export * from './useCompanyBasics'
export * from './usePaginatedMonitoringList'
export * from './useFinancialNumbers'
export * from './useFinancialNumbersConsolidated'
export * from './useFinancialStats'
export * from './useFinancialStatsConsolidated'
export * from './useFinancialPerformance'
export * from './useFinancialRatios'
export * from './useRatingCredit'
export * from './useRatingScores'
export * from './useFraudIndicators'
export * from './useFraudIndicatorsPolicyGet'
export * from './useFraudIndicatorsPolicyUpdate'
export * from './useHighlights'
export * from './useCompanyRelations'
export * from './useUserSettings'
export * from './useCompanyHierarchyWalkdown'
export * from './useCompanyHierarchyWalkup'
export * from './useStatstidende'
export * from './usePropertiesForCompany' // Tinglysning
export * from './useDetailedNumberOfEmployees'
export * from './useProductionUnits'
export * from './useCompanyHistory'
export * from './useIndustryList'
export * from './useMostRelevantList' // Would be nice with a more descriptive name
export * from './useMonitorShowList' // Would be nice with a more descriptive name
export * from './useAccountStatus'
export * from './useMonitoringStatistic'
export * from './useUserSettingsUpdate'
export * from './usePaymentRemarks'
export * from './useCustomerData'
export * from './useUserSelf'
export * from './useUpdateUserSelf'
export * from './useMonitorVisibilityUpdate'
export * from './useRemoveList'
export * from './useMonitorListPost'
export * from './useHierarchySummary'
export * from './useCustomerSettingsField'
export * from './useCustomerSettingsFieldUpdate'
export * from './subscription/useSubscriptionShow'
export * from './useUpdateUserSettingsField'
export * from './useUserSettingsField'
export * from './useCreditPolicyGet'
export * from './useCreditPolicyGetAll'
export * from './useCreditPolicyCreate'
export * from './useCreditpolicyUpdate'
export * from './useEvaluationPolicySingle'
export * from './useCompanyTypeList'
export * from './useAllUsers'
export * from './useRemoveUser'
export * from './useUpdateUser'
export * from './useSinglePolicy'
export * from './useSetSinglePolicy'
export * from './useECPDataStructure'
export * from './usePersonBulkInformation'
export * from './useCompanyBulkInformation'
