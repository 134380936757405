import { useIntl } from 'react-intl'
import { useFraudIndicatorsPolicyContext } from '../../FraudIndicatorsProvider'
import FraudIndicatorsActivator from '../FraudIndicatorsActivator'
import intl from 'localization/components'
import SectionContainer from './SectionContainer'
import { IndicatorKeys } from '../../types'

const FraudIndicatorsContent = () => {
  const {
    selectedCountry,
    indicators,
    setIndicators,
    isFraudIndicatorsPolicyDataFetching,
  } = useFraudIndicatorsPolicyContext()
  const reactIntl = useIntl()

  const titles = {
    address: reactIntl.formatMessage({
      id: intl.fraudIndicators('address'),
    }),
    financial: reactIntl.formatMessage({
      id: intl.fraudIndicators('financial'),
    }),
  }

  const handleSwitchChange = (key: string, value: boolean) => {
    setIndicators({
      ...indicators,
      [key]: value,
    })
  }

  const generateActivator = ({
    title,
    description,
    key,
  }: {
    title: string
    description: string
    key: IndicatorKeys
  }) => {
    return (
      <FraudIndicatorsActivator
        title={title}
        description={description}
        selected={indicators[key]}
        setSelected={(value: boolean) => {
          handleSwitchChange(key, value)
        }}
        key={key}
        dataCy={key}
      />
    )
  }

  const sections = {
    dk: [
      {
        title: titles.address,
        children: [
          generateActivator({
            title: reactIntl.formatMessage({
              id: intl.fraudIndicators('same-address-title'),
            }),
            description: reactIntl.formatMessage({
              id: intl.fraudIndicators('same-address-description'),
            }),
            key: 'companies_from_same_address',
          }),
          generateActivator({
            title: reactIntl.formatMessage({
              id: intl.fraudIndicators('address-frequency-title'),
            }),
            description: reactIntl.formatMessage({
              id: intl.fraudIndicators('address-frequency-description'),
            }),
            key: 'frequency_of_address_changes',
          }),
        ],
      },
      {
        title: titles.financial,
        children: [
          // generateActivator({
          //   title: reactIntl.formatMessage({
          //     id: intl.fraudIndicators('autior-bankruptcies-title'),
          //   }),
          //   description: reactIntl.formatMessage({
          //     id: intl.fraudIndicators('autior-bankruptcies-description'),
          //   }),
          //   key: 'reverse_auditor_bankruptcy_changes'
          // }),
          // generateActivator({
          //   title: reactIntl.formatMessage({
          //     id: intl.fraudIndicators('profit-employees-title'),
          //   }),
          //   description: reactIntl.formatMessage({
          //     id: intl.fraudIndicators('profit-employees-description'),
          //   }),
          //   key: 'profit_vs_employees_abnormalities'
          // }),
          // generateActivator({
          //   title: reactIntl.formatMessage({
          //     id: intl.fraudIndicators('financial-statements-title'),
          //   }),
          //   description: reactIntl.formatMessage({
          //     id: intl.fraudIndicators('financial-statements-description'),
          //   }),
          //   key: 'financial_statement_abnormalities_year_over'
          // }),
          generateActivator({
            title: reactIntl.formatMessage({
              id: intl.fraudIndicators('duplicate-statements-title'),
            }),
            description: reactIntl.formatMessage({
              id: intl.fraudIndicators('duplicate-statements-description'),
            }),
            key: 'duplicate_statements',
          }),
        ],
      },
    ],
    se: [
      {
        title: titles.address,
        children: [
          generateActivator({
            title: reactIntl.formatMessage({
              id: intl.fraudIndicators('same-address-title'),
            }),
            description: reactIntl.formatMessage({
              id: intl.fraudIndicators('same-address-description'),
            }),
            key: 'companies_from_same_address',
          }),
          generateActivator({
            title: reactIntl.formatMessage({
              id: intl.fraudIndicators('address-frequency-title'),
            }),
            description: reactIntl.formatMessage({
              id: intl.fraudIndicators('address-frequency-description'),
            }),
            key: 'frequency_of_address_changes',
          }),
        ],
      },
      {
        title: titles.financial,
        children: [
          // generateActivator(
          //   reactIntl.formatMessage({
          //     id: intl.fraudIndicators('autior-bankruptcies-title'),
          //   }),
          //   reactIntl.formatMessage({
          //     id: intl.fraudIndicators('autior-bankruptcies-description'),
          //   }),
          //   'reverse_auditor_bankruptcy_changes'
          // ),
          // generateActivator(
          //   reactIntl.formatMessage({
          //     id: intl.fraudIndicators('profit-employees-title'),
          //   }),
          //   reactIntl.formatMessage({
          //     id: intl.fraudIndicators('profit-employees-description'),
          //   }),
          //   'profit_vs_employees_abnormalities'
          // ),
          // generateActivator(
          //   reactIntl.formatMessage({
          //     id: intl.fraudIndicators('financial-statements-title'),
          //   }),
          //   reactIntl.formatMessage({
          //     id: intl.fraudIndicators('financial-statements-description'),
          //   }),
          //   'financial_statement_abnormalities_year_over'
          // ),
          generateActivator({
            title: reactIntl.formatMessage({
              id: intl.fraudIndicators('duplicate-statements-title'),
            }),
            description: reactIntl.formatMessage({
              id: intl.fraudIndicators('duplicate-statements-description'),
            }),
            key: 'duplicate_statements',
          }),
        ],
      },
    ],
    fi: [
      {
        title: titles.address,
        children: [
          generateActivator({
            title: reactIntl.formatMessage({
              id: intl.fraudIndicators('same-address-title'),
            }),
            description: reactIntl.formatMessage({
              id: intl.fraudIndicators('same-address-description'),
            }),
            key: 'companies_from_same_address',
          }),
          generateActivator({
            title: reactIntl.formatMessage({
              id: intl.fraudIndicators('address-frequency-title'),
            }),
            description: reactIntl.formatMessage({
              id: intl.fraudIndicators('address-frequency-description'),
            }),
            key: 'frequency_of_address_changes',
          }),
        ],
      },
      {
        title: titles.financial,
        children: [
          // generateActivator(
          //   reactIntl.formatMessage({
          //     id: intl.fraudIndicators('autior-bankruptcies-title'),
          //   }),
          //   reactIntl.formatMessage({
          //     id: intl.fraudIndicators('autior-bankruptcies-description'),
          //   }),
          //   'reverse_auditor_bankruptcy_changes'
          // ),
          // generateActivator(
          //   reactIntl.formatMessage({
          //     id: intl.fraudIndicators('profit-employees-title'),
          //   }),
          //   reactIntl.formatMessage({
          //     id: intl.fraudIndicators('profit-employees-description'),
          //   }),
          //   'profit_vs_employees_abnormalities'
          // ),
          // generateActivator(
          //   reactIntl.formatMessage({
          //     id: intl.fraudIndicators('financial-statements-title'),
          //   }),
          //   reactIntl.formatMessage({
          //     id: intl.fraudIndicators('financial-statements-description'),
          //   }),
          //   'financial_statement_abnormalities_year_over'
          // ),
          generateActivator({
            title: reactIntl.formatMessage({
              id: intl.fraudIndicators('duplicate-statements-title'),
            }),
            description: reactIntl.formatMessage({
              id: intl.fraudIndicators('duplicate-statements-description'),
            }),
            key: 'duplicate_statements',
          }),
        ],
      },
    ],
    no: [
      {
        title: titles.address,
        children: [
          generateActivator({
            title: reactIntl.formatMessage({
              id: intl.fraudIndicators('same-address-title'),
            }),
            description: reactIntl.formatMessage({
              id: intl.fraudIndicators('same-address-description'),
            }),
            key: 'companies_from_same_address',
          }),
          generateActivator({
            title: reactIntl.formatMessage({
              id: intl.fraudIndicators('address-frequency-title'),
            }),
            description: reactIntl.formatMessage({
              id: intl.fraudIndicators('address-frequency-description'),
            }),
            key: 'frequency_of_address_changes',
          }),
        ],
      },
      {
        title: titles.financial,
        children: [
          // generateActivator(
          //   reactIntl.formatMessage({
          //     id: intl.fraudIndicators('autior-bankruptcies-title'),
          //   }),
          //   reactIntl.formatMessage({
          //     id: intl.fraudIndicators('autior-bankruptcies-description'),
          //   }),
          //   'reverse_auditor_bankruptcy_changes'
          // ),
          // generateActivator(
          //   reactIntl.formatMessage({
          //     id: intl.fraudIndicators('profit-employees-title'),
          //   }),
          //   reactIntl.formatMessage({
          //     id: intl.fraudIndicators('profit-employees-description'),
          //   }),
          //   'profit_vs_employees_abnormalities'
          // ),
          // generateActivator(
          //   reactIntl.formatMessage({
          //     id: intl.fraudIndicators('financial-statements-title'),
          //   }),
          //   reactIntl.formatMessage({
          //     id: intl.fraudIndicators('financial-statements-description'),
          //   }),
          //   'financial_statement_abnormalities_year_over'
          // ),
          generateActivator({
            title: reactIntl.formatMessage({
              id: intl.fraudIndicators('duplicate-statements-title'),
            }),
            description: reactIntl.formatMessage({
              id: intl.fraudIndicators('duplicate-statements-description'),
            }),
            key: 'duplicate_statements',
          }),
        ],
      },
    ],
  }

  switch (selectedCountry) {
    case 'dk':
      return (
        <SectionContainer
          isLoading={isFraudIndicatorsPolicyDataFetching}
          dataCy={'fraud-indicators-danish'}
          sections={sections.dk}
        />
      )
    case 'se':
      return (
        <SectionContainer
          isLoading={isFraudIndicatorsPolicyDataFetching}
          dataCy={'fraud-indicators-swedish'}
          sections={sections.se}
        />
      )
    case 'no':
      return (
        <SectionContainer
          isLoading={isFraudIndicatorsPolicyDataFetching}
          dataCy={'fraud-indicators-norwegian'}
          sections={sections.no}
        />
      )
    case 'fi':
      return (
        <SectionContainer
          isLoading={isFraudIndicatorsPolicyDataFetching}
          dataCy={'fraud-indicators-finnish'}
          sections={sections.fi}
        />
      )
  }
}

export default FraudIndicatorsContent
